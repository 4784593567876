import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Image from "next/image";
import Link from "next/link";
/////
import advfnImage from "@/assets/images/icons/featureIn/img-featurein-advfn.webp";
import apNewsImage from "@/assets/images/icons/featureIn/img-featurein-ap2.webp";
import benzingaImage from "@/assets/images/icons/featureIn/img-featurein-Benzinga.webp";
import businessImage from "@/assets/images/icons/featureIn/img-featurein-Business.webp";
import coincodexImage from "@/assets/images/icons/featureIn/img-featurein-coincodex.webp";
import coingapeImage from "@/assets/images/icons/featureIn/img-featurein-Coingape.webp";
import creativeBloqImage from "@/assets/images/icons/featureIn/img-featurein-creative_bloq.webp";
// import crypto_NewsImage from "@/assets/images/icons/featureIn/img-featurein-crypto-news.svg";
import cryptoDailyImage from "@/assets/images/icons/featureIn/img-featurein-cryptodaily.svg";
import cryptoNewsImage from "@/assets/images/icons/featureIn/img-featurein-cryptonews.webp";
import digitalCameraWorldImage from "@/assets/images/icons/featureIn/img-featurein-digital-camera-world.webp";
// import marketwatchImage from "@/assets/images/icons/featureIn/img-featurein-Marketwatch.webp";
// import morningstarImage from "@/assets/images/icons/featureIn/img-featurein-Morningstar.webp";
import yahooImage from "@/assets/images/icons/featureIn/img-featurein-Yahoo.webp";
import streetInsiderImage from "@/assets/images/icons/featureIn/street-insider-logo-potoo.webp";

import bloombergIcon from "@/assets/images/icons/featureIn/bloomberg-icon-img.webp";
import bpImgIcon from "@/assets/images/icons/featureIn/bp-img-icon.webp";
import digitalJournal from "@/assets/images/icons/featureIn/digital-journal-img.webp";
import IBTImg from "@/assets/images/icons/featureIn/IBT-img.webp";
import jbtImg from "@/assets/images/icons/featureIn/jbt-img.webp";

//
import anadolu from "@/assets/images/icons/featureIn/anadolu.webp";
import erdon from "@/assets/images/icons/featureIn/erdon.webp";
import hurriyet from "@/assets/images/icons/featureIn/hurriyet.webp";

import chinaDaily from "@/assets/images/icons/featureIn/chinaDaily.webp";
import felash from "@/assets/images/icons/featureIn/felash.webp";
import sohu from "@/assets/images/icons/featureIn/sohu.webp";
import weibo from "@/assets/images/icons/featureIn/weibo.webp";
import Modal from "@/components/common/modal";

import bloomberg from "@/assets/images/icons/featureIn/bloomberg.jpg";
import Cryptonews from "@/assets/images/icons/featureIn/Cryptonews.png";
import { useState } from "react";

function FeatureInSection({ homeStyle }) {
  const [img, setImg] = useState(1);

  const [modal_animationFlipMedia, setmodal_animationFlipMedia] =
    useState(false);
  function tog_animationFlipMedia(img) {
    setmodal_animationFlipMedia(!modal_animationFlipMedia);
    if (!modal_animationFlipMedia) {
      setImg(img);
    }
  }

  return (
    <>
      {/* -------feature In------- */}
      <div className="center">
        <div className="featurein__header">
          <div
            className={`hot__title h3 burst ${homeStyle["home__featureIn_center"]}`}
          >
            <BurstPuckerImageTitles />
            Featured In<span style={{ marginLeft: "20px" }}></span>
            <BurstPuckerImageTitles />
          </div>
        </div>
        <div className="featurein__container ">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://apnews.com/article/3e059c05986662f270da3c676250e34d"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={50}
                  height={50}
                  loading="lazy"
                  src={apNewsImage}
                  alt="AP News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Reputable global news network; <br /> timely and comprehensive.
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                href="https://www.benzinga.com/pressreleases/25/01/43090302/seed-photo-university-of-wollongong-partner-to-shape-the-future-of-digital-art-through-research-and"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={benzingaImage}
                  alt="Benzinga"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Stock market insights, news, <br /> and trading tools hub.
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                target="_blank"
                href="https://www.erdon.ro/helyi-kozelet/2024/02/ovi-d-popot-is-dijaztak-a-vilag-elso-nft-fotoversenyen"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={erdon}
                  alt="erdon"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Unique reach, national coverage and diverse publishing
              opportunities.
            </div>
          </div>

          {/* <div className="featurein_description ">
            <div className="heading ">
              <Link href="#" className="featurein__item">
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={marketwatchImage}
                  alt="Marketwatch"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Real-time stocks, finance news, and market analysis.
            </div>
          </div> */}
          <div className="featurein_description ">
            <div className="heading ">
              <Link
                href="https://finance.yahoo.com/news/seed-photo-expands-user-convenience-115200495.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={50}
                  loading="lazy"
                  src={yahooImage}
                  alt="Yahoo Finance"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Stock quotes, finance news, and <br /> market info source.
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                target="_blank"
                href="https://www.aa.com.tr/tr/kultur/dunyanin-ilk-nft-fotograf-yarismasi-awards-photos-istanbulda-yapildi/3129380"
                className="featurein__item"
              >
                <Image
                  width={50}
                  height={70}
                  loading="lazy"
                  src={anadolu}
                  alt="anadolu"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Provides news, photographs, and <br /> videos about Türkiye
            </div>
          </div>
          {/* <div className="featurein_description">
            <div className="heading">
              <Link
                href="#"
                // target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={morningstarImage}
                  alt="Morningstar"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Investment research, tools, and market analysis platform.
            </div>
          </div> */}
          <div className="featurein_description">
            <div className="heading ">
              <button
                // href="https://www.bloomberg.com/"
                // target="_blank"
                onClick={() => tog_animationFlipMedia(1)}
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={bloombergIcon}
                  alt="binance-square"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </button>
            </div>
            <div className="Body ">
              Global business, financial news, and <br /> market data leader.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://markets.businessinsider.com/news/stocks/seed-photo-expands-user-convenience-with-direct-crypto-currency-purchases-1032596061"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={120}
                  height={50}
                  loading="lazy"
                  quality={100}
                  src={businessImage}
                  alt="Business Insider"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Breaking news, stock ratings, and <br /> financial insights.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                // href="https://coincodex.com/article/25935/the-future-of-photography-harnessing-multi-blockchain-capabilities-and-innovative-solutions/"
                href="https://coincodex.com/article/43038/seedphotos-creative-workshop-and-exclusive-gathering-in-seoul/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={163}
                  height={50}
                  loading="lazy"
                  src={coincodexImage}
                  alt="CoinCodex"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Real-time cryptocurrency prices, <br /> charts, and market info.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.creativebloq.com/features/nft-photography"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={163}
                  height={50}
                  quality={100}
                  loading="lazy"
                  src={creativeBloqImage}
                  alt="Creative Bloq"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Art, design inspiration, and creative <br /> tools resource.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                // href="https://cryptodaily.co.uk/2023/04/embracing-multi-blockchain-innovation-in-photography-industry"
                href="https://cryptodaily.co.uk/2024/05/exploring-digital-identity-in-web3-insights-from-seedphoto-at-paris-blockchain-week-2024"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={cryptoDailyImage}
                  alt="Crypto Daily"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Daily crypto news, reviews, and <br /> market updates.
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                target="_blank"
                href="https://www.hurriyet.com.tr/gundem/nft-fotograf-yarismasinda-en-iyiler-secildi-42402321"
                className="featurein__item"
              >
                <Image
                  width={130}
                  height={70}
                  loading="lazy"
                  src={hurriyet}
                  alt="hurriyet"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Breaking news from Turkey and the <br /> world.
            </div>
          </div>
          {/* <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://cryptonews.com/news/seedphoto-adds-ethereum-polygon-from-january-1-2023.htm"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={140}
                  height={50}
                  loading="lazy"
                  src={crypto_NewsImage}
                  alt="Crypto News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Cryptocurrency, blockchain, and fintech news hub.
            </div>
          </div> */}
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.digitalcameraworld.com/features/make-copyright-meaningful-protect-your-images-with-blockchains-best-solution"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={110}
                  height={50}
                  loading="lazy"
                  src={digitalCameraWorldImage}
                  alt="Digital Camera World"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Photography gear reviews, news, <br /> and magazine hub.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <button
                // href="https://cryptonews.com/news/seedphoto-adds-ethereum-polygon-from-january-1-2023.htm"
                onClick={() => tog_animationFlipMedia(2)}
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={cryptoNewsImage}
                  alt="Crypto News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </button>
            </div>
            <div className="Body">
              Cryptocurrency, blockchain, and <br /> fintech news hub.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://coingape.com/press-releases/seed-photo-adds-ethereum-and-polygon-from-january-1-2023/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={coingapeImage}
                  alt="CoinGape"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Cryptocurrency news, price <br /> analysis, and blockchain events.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.advfn.com/stock-market/stock-news/91936509/seed-photo-expands-user-convenience-with-direct-cr"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={143}
                  height={50}
                  loading="lazy"
                  src={advfnImage}
                  alt="ADVFN"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Stock prices, charts, and <br /> international market discussions.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.streetinsider.com/Globe+Newswire/Seed.Photo+Expands+User+Convenience+with+Direct+Crypto+Currency+Purchases/22112339.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={173}
                  height={50}
                  loading="lazy"
                  src={streetInsiderImage}
                  alt="Street Insider"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Breaking news, stock ratings, and <br /> financial insights.
            </div>
          </div>

          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://theubj.com/introducing-cashclick-a-new-chapter-for-artists-and-photographers/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={110}
                  height={50}
                  loading="lazy"
                  src={jbtImg}
                  alt="Crypto News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Business, Finance & Entertainment <br /> News
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.digitaljournal.com/pr/news/binary-news-network/cashclick-launches-new-platform-combining-1898142624.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={190}
                  height={90}
                  loading="lazy"
                  src={digitalJournal}
                  alt="CoinGape"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              A global media platform and <br /> content partner 
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://theblockopedia.com/seed-photo-transforming-the-future-of-photography-through-nfts-and-blockchain-innovation/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={50}
                  loading="lazy"
                  src={bpImgIcon}
                  alt="ADVFN"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              the blockchain and cryptocurrency <br /> news from all over the
              world.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.ibtimes.sg/discover-cashclick-new-way-engage-digital-creativity-75978"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={50}
                  loading="lazy"
                  src={IBTImg}
                  alt="Street Insider"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              International Business Times <br /> Business News, Financial news
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                target="_blank"
                href="https://www.sohu.com/a/759315410_121109807"
                className="featurein__item"
              >
                <Image
                  width={95}
                  height={80}
                  loading="lazy"
                  src={sohu}
                  alt="sohu"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Its content covers news, finance, <br /> real estate, images, and
              more
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                target="_blank"
                href="https://cn.chinadaily.com.cn/a/202402/18/WS65d19751a3108d04a9f8b0e5.html"
                className="featurein__item"
              >
                <Image
                  width={180}
                  height={70}
                  loading="lazy"
                  src={chinaDaily}
                  alt="chinaDaily"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              the largest English portal in China,
              <br /> providing news, business information
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                target="_blank"
                href="https://weibo.com/ttarticle/p/show?id=2309405004210428313657"
                className="featurein__item"
              >
                <Image
                  width={60}
                  height={100}
                  loading="lazy"
                  src={weibo}
                  alt="weibo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Weibo takes you on a journey to <br /> appreciate exciting moments
              in the world
            </div>
          </div>

          <div className="featurein_description ">
            <div className="heading ">
              <Link
                target="_blank"
                href="https://bullseyeu.com/archives/2275"
                className="featurein__item"
              >
                <Image
                  width={100}
                  height={80}
                  quality={100}
                  loading="lazy"
                  src={felash}
                  alt="felash"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Provide professional news and <br /> information services to its
              audience.
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal_animationFlipMedia}
        onClose={tog_animationFlipMedia}
        modalstyle={"popup-collection popup_bid scale-in-center-enter"}
        modaloverlay={"modal-overlay"}
      >
        <div className="mx-2 mt-0 mb-2">
          <div>
            <Image
              src={img == 1 ? bloomberg : Cryptonews}
              className=" position-relative"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              loading="lazy"
              alt="nft-image"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FeatureInSection;
